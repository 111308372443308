import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ClientLayoutComponent } from './client/layout/client-layout/client-layout.component';
import { AuthGuard } from './auth/auth.guard';
import { SAPSearchComponent } from './client/views/sap-search/sap-search.component';
import { NoAccessComponent } from './client/views/no-access/no-access.component';
import { RoleGuardService } from './auth/role.guard';
import { AdminModule } from './admin/admin.module';
import { AdminLayoutComponent } from './admin/layout/admin-layout/admin-layout.component';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'auth/no_access',
        component: NoAccessComponent
    },
    {
        path: 'search',
        component: SAPSearchComponent,
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: '',
        //pathMatch: 'full',
        component: ClientLayoutComponent,
        loadChildren: () => import('./client/client.module').then(m => m.ClientModule),
        canActivate: [
            AuthGuard
        ],
        canActivateChild: [
            AuthGuard
        ]
    },

    {
        path: 'admin',
        component: AdminLayoutComponent,
        data: {
            expectedRole: 'MAD_ADMIN'
        },
        canActivate: [
            AuthGuard,
            RoleGuardService
        ],
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
    },

    // {
    //     path: 'admin',
    //     component: AdminLayoutComponent,
    //     data: {
    //         expectedRole: 'EVC_ADMIN'
    //     },
    //     canActivate: [
    //         AuthGuard,
    //         RoleGuardService
    //     ],
    //     loadChildren: './admin/admin.module#AdminModule'
    // },
    {
        path: 'home',
        redirectTo: '/search'
    },
    {
        path: '**', // 404, redirect to home
        redirectTo: '/search'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    // imports: [RouterModule.forRoot(routes, { enableTracing: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
