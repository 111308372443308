import { NgModule } from '@angular/core';

//import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
//import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
//import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
//import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
//import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
//import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
//import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
//import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
//import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
//import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
//import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
//import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
//import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
//import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
//import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
//import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
//import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CdkTableModule } from '@angular/cdk/table';
import { DragDropModule } from '@angular/cdk/drag-drop';

const modules: any[] = [
    CdkTableModule,
    DragDropModule,
    //    MatButtonModule,
    MatButtonToggleModule,
    //    MatCardModule,
    //    MatCheckboxModule,
    //    MatChipsModule,
    MatDatepickerModule,
    //    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    //    MatInputModule,
    //    MatListModule,
    //    MatMenuModule,
    MatNativeDateModule,
    //    MatPaginatorModule,
    //    MatProgressBarModule,
    //    MatProgressSpinnerModule,
    MatRadioModule,
    //    MatSelectModule,
    MatSidenavModule,
    //    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    //    MatTableModule,
    //    MatTabsModule,
    MatToolbarModule
    //    MatTooltipModule
];

// Declare Module that imports/exports the @angular/material modules needed in the app
@NgModule({
    imports: [...modules],
    exports: [...modules]
})
export class MaterialDesignModule {}
