import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { provideHttpClient } from '@angular/common/http';

import { RouterModule } from '@angular/router';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './views/admin/admin.component';
import { EditProductComponent } from './views/edit-product/edit-product.component';
import { EditActivityComponent } from './views/edit-activity/edit-activity.component';
import { ProductsComponent } from './views/products/products.component';
import { ActivitiesComponent } from './views/activities/activities.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { SessionsComponent } from './views/sessions/sessions.component';
import { UsageComponent } from './views/usage/usage.component';
import { UserComponent } from './views/user/user.component';
import { CodesComponent } from './views/codes/codes.component';
import { AngularSplitModule } from 'angular-split';
import { ProductDetailsComponent } from './views/product-details/product-details.component';
import { ResourceComponent } from './views/resource/resource.component';

import { AppFacade } from '../facade/app.facade';
import { AdminFacade } from '../facade/admin.facade';

import { EVCAlertService } from '../service/alert.service';
import { NavService } from '../service/nav.service';

@NgModule({
    declarations: [
        AdminComponent,
        EditProductComponent,
        EditActivityComponent,
        ProductsComponent,
        AdminLayoutComponent,
        ActivitiesComponent,
        SessionsComponent,
        UsageComponent,
        UserComponent,
        CodesComponent,
        UserComponent,
        ProductDetailsComponent,
        ResourceComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        AdminRoutingModule,
        AngularSplitModule
    ],
    exports: [
    ],
    providers: [
        AppFacade,
        AdminFacade,
        EVCAlertService,
        NavService,
        provideHttpClient()
    ]
})
export class AdminModule {}
