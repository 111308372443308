<div class="evc-layout">
  <div *ngIf="session?.showProfit">
	<table class="evcProfit">
	  <tr>
		<td align="center" style="min-width:130px;">
		  <div style="font-size: 18px;">
			Profit
		  </div>
		  <div class="profitAmt">
			{{session?.totalSavings | currency : session.currencyCd : 'symbol-narrow' : '1.0-0' : session.locale}}
		  </div>
		</td>
		<td align="right">
			<button class="spot-button" style="height:55px;background-color:transparent;" (click)="editProducts()" ><mat-icon aria-hidden="false" svgIcon="summary.svg" class="smallIcon fillWhite"></mat-icon></button>

		  </td>
		</tr>
	  </table>

  </div>

  <header class="pageHeader">
	<div horizontalAlign="stretch" verticalAlign="center" class="evcPageHeader"> <!-- evc header -->
	  <div class="evcBrandLink">
		<button class="spot-button spot-button--icon-only evcHomeLink" (click)="goHome()" title="Home" alternativeText="Home" >
		    <svg class="spot-icon" aria-labelledby="title">
			  <title>Home</title>
			  <use xlink:href="/assets/spot-library/dist/icons/spot_icons.svg#home"></use>
			</svg>
		</button>
		<img src="/assets/images/IDEXX_logo.resource" class="headerLogo"/>
	  </div>
	  <div style="flex:1">
		<h1 class="appName">Economic Value Calculator</h1>
	  </div>
	  <div class="evcBrandLink">
	  </div>
	  <!-- <ng-template alignmentBump="left"> -->
		<!-- </ng-template> -->

	</div>
  </header>
  <div class="evc-body">
	<div class="evcBody">
	  <div id="mainToastDiv"></div>

	  <router-outlet></router-outlet>

	</div>
  </div>

  <evc-client-nav></evc-client-nav>
  <evc-client-footer></evc-client-footer>

</div>
