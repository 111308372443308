import { NgModule } from '@angular/core';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap'
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxCurrencyDirective } from 'ngx-currency';

import { MaterialDesignModule } from '../../material-design/material-design.module';

import { SAPSearchComponent } from './views/sap-search/sap-search.component';
import { SelectProductsComponent } from './views/select-products/select-products.component';
import { ProductComponent } from './views/product/product.component';
import { EvcSummaryComponent } from './views/evc-summary/evc-summary.component';
import { DoneComponent } from './views/done/done.component';

import { ViewSummariesComponent } from './components/view-summaries/view-summaries.component';

import { ClientRoutingModule } from './client-routing.module';

import { ClientLayoutComponent } from './layout/client-layout/client-layout.component';
import { HomeComponent } from './views/home/home.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { AdjustPriceComponent } from './components/adjust-price/adjust-price.component';
import { ConfirmationComponent } from "./components/confirmation/confirmation.component";
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { EmailDialogComponent } from './components/email-dialog/email-dialog.component';
import { EditGrowthComponent } from './components/edit-growth/edit-growth.component';
import { ClientFooterComponent } from './components/client-footer/client-footer.component';
import { LoadingIndicatorComponent } from '../components/loading-indicator/loading-indicator.component';

import { ClientNavComponent } from './components/client-nav/client-nav.component';
import { PartialSessionComponent } from './components/partial-session/partial-session.component';
import { ProductItemComponent } from './components/product-item/product-item.component';

@NgModule({
    declarations: [
        ConfirmationComponent,
        SAPSearchComponent,
        SelectProductsComponent,
        ProductComponent,
        EvcSummaryComponent,
        DoneComponent,
        ClientLayoutComponent,
        HomeComponent,
        AdjustPriceComponent,
        ProgressBarComponent,
        EmailDialogComponent,
        EditGrowthComponent,
        ClientFooterComponent,
        ViewSummariesComponent,
        LoadingIndicatorComponent,
        ClientNavComponent,
        PartialSessionComponent,
        ProductItemComponent
    ],
    imports: [
        NgbTypeaheadModule,
        NgbPopoverModule,
        NgxCurrencyDirective,
        FormsModule,
        CommonModule,
        ClientRoutingModule,
        MaterialDesignModule
    ],
    exports: [
    ]
})
export class ClientModule {}
