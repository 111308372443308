import { Component, OnInit } from '@angular/core';
import { AuthService } from 'client/app/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'evc-auth-callback',
    templateUrl: './auth-callback.component.html',
    styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService
    ) {}

    public ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.loginWithCode(params.code);
        });
    }

    private async loginWithCode(code: string): Promise<void> {
        try {
            const authResp = await this.authService.loginWithCode(code, '/search');
        } catch (err) {
            console.error("Error logging in", err);
        }
    }
}
