import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { EVCAlertService } from './service/alert.service';
import { IconService } from './service/icon.service';
import { AdminService } from './service/admin.service';
import { AppFacade } from './facade/app.facade';
import { AdminFacade } from './facade/admin.facade';
import { NavService } from './service/nav.service';
import { ClientModule } from './client/client.module';
import { AdminModule } from './admin/admin.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaterialDesignModule } from '../material-design/material-design.module';
import { AuthModule } from './auth/auth.module';
import { VersionService } from './service/version.service';
import { AngularSplitModule } from 'angular-split';

import { CurrencyPipe, DecimalPipe } from '@angular/common';

@NgModule({
    declarations: [
        AppComponent
    ],
    exports: [],
    bootstrap: [AppComponent], imports: [AngularSplitModule,
        AppRoutingModule,
        BrowserModule,
        ClientModule,
        AdminModule,
        AuthModule,
        FormsModule,
        MaterialDesignModule,
        NgbModule,
        OAuthModule.forRoot()], providers: [
            CurrencyPipe,
            DecimalPipe,
            DatePipe,
            NgbActiveModal,
            AppFacade,
            AdminFacade,
            IconService,
            AdminService,
            EVCAlertService,
            VersionService,
            NavService,
            AngularSplitModule,
            provideHttpClient(withInterceptorsFromDi())
        ]
})
export class AppModule {}
